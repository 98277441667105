import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Button = styled.button(() => [
  // The common button styles added with the tw import
  tw`font-sans text-lg px-8 py-2 rounded focus:outline-none`,
  tw`transform transition-transform duration-75`,

  // Use the variant grouping feature to add variants to multiple classes
  tw`hocus:(scale-105 text-yellow-400)`,
]);

export default Button;
