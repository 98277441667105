import React from 'react';
import { GlobalStyles } from 'twin.macro';

export type LayoutProps = unknown;

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <h1>Welcome to Layout!</h1>
      {children}
    </>
  );
};

export default Layout;
