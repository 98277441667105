import { graphql, Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout';
import { Button } from '@100shapes/elements';

export const query = graphql`
  query {
    allGlossaryTerm(sort: { order: ASC, fields: term }) {
      edges {
        node {
          term
          slug
        }
      }
    }
  }
`;

export type IndexPageProps = {
  data: {
    allGlossaryTerm: GatsbyTypes.GlossaryTermConnection;
  };
};

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const terms = data.allGlossaryTerm.edges.map(({ node }) => node);
  return (
    <Layout>
      <h1>UX Glossary</h1>
      <Button>Click me</Button>
      <ol>
        {terms.map(({ slug, term }) =>
          slug && term ? (
            <li key={`term-${slug}`}>
              <Link to={slug}>{term}</Link>
            </li>
          ) : null
        )}
      </ol>
      <h2>A</h2>
      <h2>B</h2>
    </Layout>
  );
};

export default IndexPage;
